import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useStats } from "context/providers/stats";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const options = {
  responsive: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      min: 0,
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      min: 0,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const getLabelDateFormat = (type) => {
  switch (type) {
    case "year":
      return "YYYY";
    case "month":
      return "MM-YYYY";
    case "hour":
      return "DD-MM-YYYY HH:mm";
    default:
      return "DD-MM-YYYY";
  }
};

const OrdersChart = () => {
  const {
    state: {
      query,
      data: { orders_by_ordered_at },
    },
  } = useStats();

  const orders_count = orders_by_ordered_at.map(
    ({ count }) => count
  );

  const orders_total_count = orders_count.reduce(
    (acc, value) => acc + value,
    0
  );

  const orders_total_price_in_pln = orders_by_ordered_at.map(
    ({ total_price_in_pln }) => total_price_in_pln
  );

  const orders_total_total_price_in_pln =
    orders_total_price_in_pln.reduce(
      (acc, value) => acc + value,
      0
    );

  const data = {
    labels: orders_by_ordered_at.map(({ name }) =>
      moment(name).format(
        getLabelDateFormat(query.histogram_interval)
      )
    ),
    datasets: [
      {
        label: "Ilość zamówień",
        data: orders_count,
        fill: true,
        borderWidth: 2,
        borderColor: "rgb(255, 174, 99)",
        backgroundColor: "rgba(255, 208, 99, 0.2)",
        yAxisID: "y",
      },
      {
        label: "Wartość zamówień",
        data: orders_total_price_in_pln,
        fill: true,
        borderWidth: 2,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        yAxisID: "y1",
      },
    ],
  };

  return (
    <>
      <p>
        Ilość zamówień:{" "}
        <strong>
          {numberWithSpaces(orders_total_count)}
        </strong>
      </p>
      <p>
        Wartość zamówień:{" "}
        <strong>
          {numberWithSpaces(
            orders_total_total_price_in_pln.toFixed(2)
          )}
        </strong>
      </p>
      <Line
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={data}
      />
    </>
  );
};

export default OrdersChart;
